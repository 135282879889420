
.advs-item {
  margin-bottom: 20px;
}
.advs-image-cover {
  object-fit: cover;
  height: 150px;
  width: 250px;
}

@primary-color: #7f7fd5;@link-color: #86a8e7;